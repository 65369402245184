@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  overflow-x: hidden;
}

button {
  cursor: pointer;
}
button:focus {
  outline: none;
  box-shadow: none;
}

a {
  text-decoration: inherit;
  color: inherit;
}

ul {
  list-style: none;
}

::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
::-webkit-scrollbar {
  width: 6px;
  background: #f4f4f4;
}
::-webkit-scrollbar-thumb {
  background: #dad7d7;
  border-radius: 8px;
}

.boldOnHover:hover {
  font-weight: 500;
}
.carousel-container {
  width: 100%;
}
.clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .swiper-container {
  margin-right: 10% !important;
} */

.swiper-button-next,
.swiper-button-next::after {
  color: black !important;
}

.swiper-button-prev,
.swiper-button-prev::after {
  color: black !important;
}

@media screen and (max-width: 600px) {
  .swiper-button-next,
  .swiper-button-next::after {
    visibility: hidden;
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-prev::after {
    visibility: hidden;
    display: none;
  }
}

.input-range__track--active,
.input-range__slider {
  background: black !important;
  border-color: black !important;
}

@layer utilities {
  .passionateTheme {
    background: linear-gradient(
      90deg,
      rgba(255, 115, 0, 1) 0%,
      rgba(255, 115, 0, 1) 0%,
      rgba(255, 222, 1, 1) 100%
    );
  }
  .professionalTheme {
    background: linear-gradient(
      90deg,
      rgba(1, 0, 2, 1) 0%,
      rgba(1, 0, 2, 1) 0%,
      rgba(53, 51, 205, 1) 100%
    );
  }
  .enterprenurTheme {
    background: linear-gradient(
      90deg,
      rgba(1, 0, 2, 1) 0%,
      rgba(1, 0, 2, 1) 0%,
      rgba(206, 206, 209, 1) 100%
    );
  }
  .influencerTheme {
    background: linear-gradient(
      90deg,
      rgba(80, 65, 0, 1) 0%,
      rgba(80, 65, 0, 1) 0%,
      rgba(200, 145, 22, 1) 100%
    );
  }
  .websiteGradient {
    background: linear-gradient(
      90deg,
      rgba(25, 25, 25, 1) 0%,
      rgba(25, 25, 25, 1) 0%,
      rgba(148, 59, 2, 1) 100%
    );
  }
  .customGridCols1 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }
  @media (min-width: 1024px) {
    .customGridCols1 {
      grid-template-columns: 1fr 1px 1fr;
      gap: 16px;
    }
  }
  .customGridLayout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr fit;
  }
  @media (min-width: 1024px) {
    .customGridLayout {
      grid-template-columns: max-content 1fr 1fr;
    }
  }
  .websiteBgVideo {
    min-height: 90vh;
    max-height: 90vh;
  }
  .websiteHeroSectionWrapper {
    min-height: 90vh;
  }
  @media (min-width: 1024px) {
    .websiteBgVideo {
      min-height: 100vh;
      max-height: 100vh;
    }
    .websiteHeroSectionWrapper {
      min-height: 100vh;
    }
  }
}
